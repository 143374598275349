<template lang="">
  <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <div class="row mt-0 mb-4">
        <div
          class="col-12 col-md-12 m-0 p-0"
          style="margin-top: -5px !important;"
        >
          <DivisorColor :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`" />
        </div>
      </div>
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12" sm="12" md="8">
            <span class="card-label font-weight-bolder text-dark"
              >Seguimiento Cooperación descentralizada <br />
            </span>
            <br />
            <v-skeleton-loader
              v-if="skeletonLoading"
              type="list-item-two-line"
            ></v-skeleton-loader>
            <div v-if="!skeletonLoading">
              <span class="text-muted mt-3 font-weight-bold font-size-md">
                {{ codigoProyecto }}
              </span>
              <br />
              <span class="text-muted mt-3 font-weight-bold font-size-md">
                {{ datosProyecto.nombreProyecto }}
              </span>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-btn
              color="light-blue-502"
              dark
              class="white--text mb-2 float-right"
              @click="modalNuevo"
            >
              Registrar seguimiento
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text class="pl-0 pr-0">
        <v-data-table
          class="elevation-0"
          :headers="headers"
          :items="seguimientos"
          :search="filtro"
          :loading="tableLoading"
          no-data-text="No se encontraron registros"
          info-empty="No se encontraron registros"
          zero-records="No se encontraron registros"
          loading-text="Cargando..."
          :header-props="{
            sortByText: 'Ordenar por'
          }"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}'
          }"
        >
          <template v-slot:top>
            <!-- v-container, v-col and v-row are just for decoration purposes. -->
            <v-container fluid>
              <v-row>
                <v-col cols="6" md="6" sm="12" xs="12">
                  <v-text-field
                    filled
                    autocomplete="off"
                    dense
                    v-model="filtro"
                    label="Fecha registro, inicio o finalización"
                    maxlength="100"
                  >
                    <v-icon slot="append" color="black">
                      mdi-magnify
                    </v-icon>
                  </v-text-field>
                </v-col>

                <!-- <v-col cols="6" md="6" sm="12" xs="12">
                  <v-select
                    v-model="estadoProyecto"
                    :items="estadosProyecto"
                    :loading="false"
                    dense
                    filled
                    label="Estado"
                    :no-data-text="'No existen estados registrados'"
                    menu-props="auto"
                    return-object
                  ></v-select>
                </v-col> -->

                <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                  <v-btn
                    class="ma-0 white--text d-none"
                    medium
                    color="light-blue darken-2"
                  >
                    <v-icon left>mdi-magnify</v-icon> Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.fechaCreacion | formatDate }}</td>
              <td>{{ item.fechaInicioSeguimiento | formatDate }}</td>
              <td>{{ item.fechaFinalizacionSeguimiento | formatDate }}</td>
              <td class="text-right">{{ item.porcentajeEjecucionFisica  != null ? item.porcentajeEjecucionFisica + `%` : `N/A`}}</td>
              <td class="text-right">
                {{ item.porcentajeEjecucionFinanciera  != null ? item.porcentajeEjecucionFinanciera + `%` : `N/A`}}
              </td>
              <td class="text-right">
                {{
                  item.costoEjecutadoDolares != null ?
                    parseFloat(item.costoEjecutadoDolares).toLocaleString(
                      "es-GT",
                      {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 2
                      }
                    ) : `N/A`
                }}
              </td>
              <td class="text-right">
                {{ item.tipoCambio ? item.tipoCambio.toFixed(2) : `N/A` }}
              </td>
              <td class="text-right">
                {{
                  item.costoTotalQuetzales != null ?
                  parseFloat(item.costoTotalQuetzales).toLocaleString("es-GT", {
                    style: "currency",
                    currency: "GTQ",
                    maximumFractionDigits: 2
                  }) : `N/A`
                }}
              </td>
              <td>
                <v-chip
                  label
                  class="ma-2"
                  :color="
                    item.estadosId === 1
                      ? 'blue-grey lighten-4'
                      : item.estadosId === 2
                      ? 'deep-orange lighten-4'
                      : item.estadosId === 4
                      ? 'light-blue lighten-4'
                      : item.estadosId === 6
                      ? 'cyan lighten-4'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-4'
                      : 'pink lighten-4'
                  "
                  :text-color="
                    item.estadosId === 1
                      ? 'blue-grey lighten-1'
                      : item.estadosId === 2
                      ? 'deep-orange lighten-1'
                      : item.estadosId === 4
                      ? 'light-blue lighten-1'
                      : item.estadosId === 6
                      ? 'cyan darken-1'
                      : item.estadosId === 7
                      ? 'deep-orange lighten-1'
                      : 'pink darken-1'
                  "
                  small
                >
                  {{ item.estadosId == 1 ? `En registro` : item.estado }}
                </v-chip>
              </td>
              <td>
                <v-btn
                  v-if="item.estadoProyectoId === 1"
                  class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="mostrarModalActualizar(item.id, item.codigo)"
                >
                  <v-icon left>mdi-pencil</v-icon> Actualizar
                </v-btn>

                <v-btn
                  class="ma-2  btn-bg-light blue-grey--text lighten-2--text font-weight-bold text-capitalize d-none"
                  small
                  depressed
                  color="blue-grey lighten-5"
                >
                  <v-icon left>mdi-eye</v-icon>
                </v-btn>

                <v-btn
                  v-if="item.estadosId === 1 || item.estadosId === 4"
                  class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="obtenerDatosSeguimiento(item)"
                >
                  <v-icon left>{{
                    item.estadosId === 1 ? `mdi-pencil` : `mdi-eye`
                  }}</v-icon>
                  {{ item.estadosId === 1 ? `Actualizar` : `Visualizar` }}
                </v-btn>
                <v-btn
                  v-if="item.estadosId === 1 || item.estadosId === 4"
                  class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="mostrarDialogEstado(item.id, 2)"
                >
                  <v-icon left>mdi-delete</v-icon> Inhabilitar
                </v-btn>

                <v-btn
                  v-if="item.estadosId === 2"
                  class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                  small
                  depressed
                  color="blue-grey lighten-5"
                  @click="mostrarDialogEstado(item.id, 1)"
                >
                  <v-icon left>mdi-shape-circle-plus</v-icon> Habilitar
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- inicio:: dialog registro -->
    <v-dialog
      v-model="dialogRegistro"
      persistent
      max-width="1024px"
      transition="scroll-y-transition"
      scrollable
    >
      <v-card tile>
        <v-card-title>
          {{ modalTitle }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnRegistroLoading"
            @click="dialogRegistro = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text  class="pl-0 pr-0">
          <v-tabs
            v-model="tab"
            background-color="light-blue-502"
            centered
            dark
            icons-and-text
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1">
              Información General
              <v-icon>mdi-format-list-bulleted-square</v-icon>
            </v-tab>

            <v-tab href="#tab-2" :disabled="!seguimientoRegistrado">
              Ejecución Física
              <v-icon>mdi-arrow-collapse-up</v-icon>
            </v-tab>

            <v-tab href="#tab-3" :disabled="!seguimientoRegistrado">
              Ejecución Financiera
              <v-icon>mdi-cash-100</v-icon>
            </v-tab>
            <v-tab href="#tab-4" :disabled="!seguimientoRegistrado">
              Documentos adicionales
              <v-icon>mdi-file-upload-outline</v-icon>
            </v-tab>
            <v-tab href="#tab-5" :disabled="!seguimientoRegistrado" @click="obtenerDatosSeguimientoConfirmacion(seguimientoId)">
              Confirmar información
              <v-icon>mdi-check-all</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" touchless>
            <v-tab-item :value="'tab-1'">
              <v-card flat>
                <v-card-text style="background: #fff;">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                      sm="12"
                      class="pt-0 mt-4 pb-0 mb-0"
                    >
                      <v-subheader class="text-h5 black--text">
                        Información general
                      </v-subheader>
                    </v-col>
                  </v-row>
                  <v-form
                    ref="formProyeccionDesembolsos"
                    v-on:submit.prevent="registrarItem"
                    v-model="validForm"
                  >
                    <v-row class="pt-4">
                      <!--inicio:: fehcas-->
                      <v-col cols="12" md="6" sm="6" xs="12" class="pb-0">
                        <v-menu
                          ref="menuFechaInicioSeguimiento"
                          v-model="menuFechaInicioSeguimiento"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                          :disabled="datosRegistro.estadosId == 2  || datosRegistro.estadosId == 4"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              dense
                              filled
                              color="blue-grey lighten-2"
                              v-model="computedFechaInicio"
                              label="Fecha de inicio del seguimiento"
                              hint="DD/MM/AAAA"
                              persistent-hint
                              append-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                              v-mask="'##/##/####'"
                              maxlength="10"
                              :disabled="
                                datosRegistro.estadosId == 2 ||
                                  datosRegistro.estadosId == 4
                              "
                              :rules="[
                                required('fecha inicio del seguimiento')
                              ]"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="dateFechaInicioSeguimiento"
                            no-title
                            @input="menuFechaInicioSeguimiento = false"
                            locale="es"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <v-col cols="12" md="6" sm="6" xs="12" class="pb-0">
                        <v-menu
                          ref="menuFechaFinalizacionSeguimiento"
                          v-model="menuFechaFinalizacionSeguimiento"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                          :disabled="
                            datosRegistro.estadosId == 2 ||
                              datosRegistro.estadosId == 4
                          "
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              dense
                              filled
                              color="blue-grey lighten-2"
                              v-model="computedFechaFinalizacion"
                              label="Fecha de finalización del seguimiento"
                              hint="DD/MM/AAAA"
                              persistent-hint
                              append-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                              v-mask="'##/##/####'"
                              maxlength="10"
                              :disabled="
                                datosRegistro.estadosId == 2 ||
                                  datosRegistro.estadosId == 4
                              "
                              :rules="[
                                required('fecha finalización del seguimiento')
                              ]"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="dateFechaFinalizacionSeguimiento"
                            no-title
                            @input="menuFechaFinalizacionSeguimiento = false"
                            locale="es"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <!--fin:: fechas -->
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12" sm="12" class="pt-0">
                        <v-btn
                          color="light-blue-502"
                          class="white--text mb-2 float-right"
                          type="submit"
                          :elevation="0"
                          :disabled="
                            !validForm ||
                              datosRegistro.estadosId == 2 ||
                              datosRegistro.estadosId == 4
                          "
                          :loading="btnRegistroLoading"
                        >
                          {{
                            accion == 1
                              ? `Registrar información`
                              : `Actualizar información`
                          }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item :value="'tab-2'">
              <v-card flat>
                <v-card-text style="background: #fff;">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                      sm="12"
                      class="pt-0 mt-4 pb-0 mb-0"
                    >
                      <v-subheader class="text-h5 black--text">
                        Ejecución física
                      </v-subheader>
                    </v-col>
                  </v-row>
                  <v-form
                    ref="formEjecucionFisica"
                    v-on:submit.prevent="registrarEjecucionFisica"
                    v-model="validFormEjecucionFisica"
                  >
                    <v-row v-if="seguimientoRegistrado" class="pt-4">
                      <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                        <v-text-field
                          dense
                          filled
                          autocomplete="off"
                          class="required"
                          color="blue-grey lighten-2"
                          v-model="porcentajeEjecucionFisica"
                          label="Porcentaje de ejecución física"
                          suffix="%"
                          :rules="[
                            required('porcentaje de ejecución física'),
                            minLength('porcentaje de ejecución física', 1),
                            maxLength('porcentaje de ejecución física', 6),
                            decimals('Avance'),
                            maxNumber('porcentaje de ejecución física', 100)
                          ]"
                          maxlength="6"
                          :disabled="
                            datosRegistro.estadosId == 2 ||
                              datosRegistro.estadosId == 4
                          "
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                        <v-textarea
                          dense
                          filled
                          autocomplete="off"
                          class="required"
                          color="blue-grey lighten-2"
                          v-model="datosRegistro.informacionAdicionalFisica"
                          label="Información adicional de la ejecución física"
                          :rules="[
                            required(
                              'Información adicional de la ejecución física'
                            ),
                            minLength(
                              'Información adicional de la ejecución física',
                              5
                            ),
                            maxLength(
                              'Información adicional de la ejecución física',
                              2000
                            )
                          ]"
                          maxlength="2000"
                          rows="3"
                          :disabled="
                            datosRegistro.estadosId == 2 ||
                              datosRegistro.estadosId == 4
                          "
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12" sm="12" class="pt-0">
                        <v-btn
                          color="light-blue-502"
                          class="white--text mb-2 float-right"
                          type="submit"
                          :elevation="0"
                          :disabled="
                            !validFormEjecucionFisica ||
                              datosRegistro.estadosId == 2 ||
                              datosRegistro.estadosId == 4
                          "
                          :loading="btnEjecucionFisicaLoading"
                        >
                          Registrar ejecución física
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-3'">
              <v-card flat>
                <v-card-text style="background: #fff;">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                      sm="12"
                      class="pt-0 mt-4 pb-0 mb-0"
                    >
                      <v-subheader class="text-h5 black--text">
                        Ejecución financiera
                      </v-subheader>
                    </v-col>
                  </v-row>
                  <v-form
                    ref="formEjecucionFinanciera"
                    v-on:submit.prevent="registrarEjecucionFinanciera"
                    v-model="validFormEjecucionFinanciera"
                  >
                    <v-row class="pt-4">
                      <v-col cols="12" md="6" sm="6" class="pt-0 pb-0">
                        <v-text-field
                          dense
                          filled
                          autocomplete="off"
                          class="required"
                          color="blue-grey lighten-2"
                          v-model="porcentajeEjecucionFinanciera"
                          label="Porcentaje de ejecución financiera"
                          suffix="%"
                          :rules="[
                            required('porcentaje de ejecución financiera'),
                            minLength('porcentaje de ejecución financiera', 1),
                            maxLength('porcentaje de ejecución financiera', 6),
                            decimals('porcentaje de ejecución financiera'),
                            maxNumber('porcentaje de ejecución financiera', 100)
                          ]"
                          maxlength="6"
                          :disabled="
                            datosRegistro.estadosId == 2 ||
                              datosRegistro.estadosId == 4
                          "
                        >
                        </v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                        sm="12"
                        xs="12"
                        class="pt-0 pb-0"
                      >
                        <v-textarea
                          dense
                          filled
                          autocomplete="off"
                          class="required"
                          color="blue-grey lighten-2"
                          v-model="datosRegistro.informacionAdicionalFinanciera"
                          label="Información adicional de la ejecución financiera"
                          :rules="[
                            required(
                              'Información adicional de la ejecución financiera'
                            ),
                            minLength(
                              'Información adicional de la ejecución financiera',
                              5
                            ),
                            maxLength(
                              'Información adicional de la ejecución financiera',
                              2000
                            )
                          ]"
                          maxlength="2000"
                          rows="3"
                          :disabled="
                            datosRegistro.estadosId == 2 ||
                              datosRegistro.estadosId == 4
                          "
                        >
                        </v-textarea>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                        <v-menu
                          ref="menuFechaTipoCambio"
                          v-model="menuFechaTipoCambio"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                          :disabled="
                            datosRegistro.estadosId == 2 ||
                              datosRegistro.estadosId == 4
                          "
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              dense
                              filled
                              color="blue-grey lighten-2"
                              :clearable="true"
                              @click:clear="dateFechaTipoCambioClear"
                              v-model="computedFechaTipoCambio"
                              label="Fecha de tipo de cambio"
                              hint="DD/MM/AAAA"
                              persistent-hint
                              append-icon="mdi-calendar"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                              v-mask="'##/##/####'"
                              maxlength="10"
                              :disabled="
                                datosRegistro.estadosId == 2 ||
                                  datosRegistro.estadosId == 4
                              "
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="dateFechaTipoCambio"
                            no-title
                            @input="menuFechaTipoCambio = false"
                            locale="es"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <!--fin:: fechas -->
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                        <v-text-field
                          dense
                          filled
                          autocomplete="off"
                          :class="
                            computedFechaTipoCambio != null ? `required` : ``
                          "
                          color="blue-grey lighten-2"
                          suffix="$"
                          v-model="costoEjecutadoDolares"
                          label="Costo ejecutado en dólares"
                          :rules="[
                            computedFechaTipoCambio != null
                              ? required('costo ejecutado en dólares')
                              : true,
                            computedFechaTipoCambio != null
                              ? notComma('costo ejecutado en dólares')
                              : true,
                            computedFechaTipoCambio != null
                              ? decimals10('costo ejecutado en dólares')
                              : true
                          ]"
                          maxlength="30"
                          :disabled="
                            datosRegistro.estadosId == 2 ||
                              datosRegistro.estadosId == 4
                          "
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                        <v-text-field
                          dense
                          filled
                          autocomplete="off"
                          :class="computedFechaTipoCambio != null ? `required` : ``"
                          color="blue-grey lighten-2"
                          v-model="tipoCambio"
                          label="Tipo de cambio"
                          :rules="[
                            computedFechaTipoCambio != null
                              ? required('tipo de cambio')
                              : true,
                            computedFechaTipoCambio != null
                              ? decimals10('tipo de cambio')
                              : true
                          ]"
                          maxlength="30"
                          :disabled="
                            datosRegistro.estadosId == 2 ||
                              datosRegistro.estadosId == 4
                          "
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="4" sm="12" class="pt-0 pb-0">
                        <v-text-field
                          dense
                          filled
                          :disabled="true"
                          autocomplete="off"
                          :class="
                            computedFechaTipoCambio != null ? `required` : ``
                          "
                          color="blue-grey lighten-2"
                          suffix="GTQ"
                          v-model="costoTotalQuetzales"
                          label="Costo total quetzales"
                          maxlength="30"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12" sm="12" class="pt-0">
                        <v-btn
                          color="light-blue-502"
                          class="white--text mb-2 float-right"
                          type="submit"
                          :elevation="0"
                          :disabled="
                            !validFormEjecucionFinanciera ||
                              datosRegistro.estadosId == 2 ||
                              datosRegistro.estadosId == 4
                          "
                          :loading="btnEjecucionFinancieraLoading"
                        >
                          Registrar ejecución financiera
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>

            <v-tab-item :value="'tab-4'">
              <v-card flat>
                <v-card-text style="background: #fff;">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                      sm="12"
                      class="pt-0 mt-4 pb-0 mb-0"
                    >
                      <v-subheader class="text-h5 black--text">
                        Documentos adicionales
                      </v-subheader>
                    </v-col>
                  </v-row>
                  <v-form
                    ref="formEjecucionFinanciera"
                    v-on:submit.prevent="cargarArchivo"
                    v-model="validFormArchivo"
                    v-if="
                      datosRegistro.estadosId != 2 &&
                        datosRegistro.estadosId != 4
                    "
                  >
                    <v-row class="pt-4">
                      <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                        <v-select
                          v-model="datosDocumento.seccionDocumento"
                          :items="tiposDocumentos"
                          :loading="false"
                          dense
                          filled
                          class="required"
                          label="Seleccione el tipo de documento a cargar"
                          item-text="tipo"
                          item-value="id"
                          menu-props="auto"
                          :rules="[selectRequired('tipo de archivo')]"
                        >
                        </v-select>
                      </v-col>

                      <v-col cols="12" md="6" sm="12" class="pt-6 pb-0">
                        <template>
                          <v-file-input
                            id="docFile"
                            dense
                            filled
                            placeholder="Seleccionar archivo .pdf, .jpg o .png"
                            :label="
                              datosDocumento.seccionDocumento &&
                              datosDocumento.seccionDocumento == 1
                                ? `Seleccione documento de ejecución física` +
                                    datosDocumento.seccionDocumento &&
                                  datosDocumento.seccionDocumento == 2
                                : `Seleccione documento adicional`
                            "
                            append-icon="mdi-file-pdf-outline"
                            prepend-icon=""
                            accept="application/pdf,image/jpeg,image/png"
                            @change="onFileChange"
                            :rules="[fileRequired('documento')]"
                            :show-size="1000"
                            ref="docFile"
                            class="required"
                          >
                            <template v-slot:selection="{ index, text }">
                              <v-chip v-if="index < 2" small label color="primary">
                                {{ text }}
                              </v-chip>
                              <span
                                v-else-if="index === 2"
                                class="
                                                text-overline
                                                grey--text
                                                text--darken-3
                                                mx-2
                                            "
                              >
                                +{{ files.length - 2 }} archivo(s)
                              </span>
                            </template>
                          </v-file-input>
                        </template>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12" sm="12" xs="12">
                        <v-text-field
                          filled
                          autocomplete="off"
                          dense
                          v-model="datosDocumento.nombreDocumento"
                          label="Nombre del archivo"
                          :rules="[required('nombre del archivo')]"
                          maxlength="100"
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="12" sm="12" class="pt-0">
                        <v-btn
                          color="light-blue-502"
                          class="white--text mb-2 float-right"
                          type="submit"
                          :elevation="0"
                          :disabled="!validFormArchivo"
                          :loading="btnRegistroArchivoLoading"
                        >
                          Cargar archivo
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row>
                    <v-divider></v-divider>
                    <v-col cols="12" md="12" sm="12" class="pb-0">
                      <v-subheader class="text-h6 black--text pb-0">
                        Documentos cargados
                      </v-subheader>
                    </v-col>

                    <!--inicio:: tabla de documentos cargados -->
                    <v-col cols="12" md="12" sm="12" class="pt-4">
                      <v-data-table
                        class="elevation-1"
                        :headers="headersDocumentos"
                        :items="documentosCargados"
                        :loading="tableLoadingDocumentos"
                        hide-default-footer
                        :footer-props="{
                          showFirstLastPage: true,
                          firstIcon: 'mdi-page-first',
                          lastIcon: 'mdi-page-last',
                          prevIcon: 'mdi-chevron-left',
                          nextIcon: 'mdi-chevron-right',
                          'items-per-page-text': 'Registros por página',
                          pageText: '{0}-{1} de {2}'
                        }"
                      >
                        <template v-slot:item="{ item }">
                          <tr>
                            <td>{{ item.seccionDocumento == 1 ? `Documento ejecución física` : `Documento adicional` }}</td>
                            <td>{{ item.nombreDocumento }}</td>
                            <!-- <td>
                              <v-chip
                                label
                                class="ma-2"
                                :color="
                                  item.cargado === 0
                                    ? 'deep-orange lighten-4'
                                    : item.cargado > 0
                                    ? 'teal lighten-4'
                                    : 'pink lighten-4'
                                "
                                :text-color="
                                  item.cargado === 0
                                    ? 'deep-orange lighten-1'
                                    : item.cargado > 0
                                    ? 'teal lighten-1'
                                    : 'pink darken-1'
                                "
                                small
                              >
                                {{ item.cargado === 0 ? `Sin cargar` : `Cargado` }}
                              </v-chip>
                            </td> -->
                            <td>
                              <v-btn
                                v-if="item.estadosId == 1"
                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                small
                                :disabled="btnEliminarLoading"
                                depressed
                                color="blue-grey lighten-5"
                                @click="descargarArchivo(item.pathDocumento)"
                              >
                                <v-icon left>mdi-file-download-outline</v-icon>
                                Visualizar
                              </v-btn>
                              <v-btn
                                v-if="
                                  item.cargado > 0 &&
                                    ((tipoUsuario != `int` && !seccionesBloqueadas) ||
                                      tipoUsuario === `int`)
                                "
                                class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                small
                                :disabled="btnEliminarLoading"
                                :loading="btnEliminarLoading"
                                depressed
                                color="blue-grey lighten-5"
                                @click="eliminarArchivo(item.asignacionId)"
                              >
                                <v-icon left>mdi-delete</v-icon> Eliminar
                              </v-btn>
                            </td>
                          </tr>
                        </template>

                        <template v-slot:no-data>
                          <p class="text-h7">
                            <v-icon left>mdi-alert</v-icon> No existe información
                            registrada de proyección de fuente cooperante
                          </p>
                        </template>
                      </v-data-table>
                    </v-col>
                    <!--fin:: tabla de documentos cargados -->
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item :value="'tab-5'">
              <v-card flat>
                <v-card-text style="background: #fff;">
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                      sm="12"
                      class="pt-0 mt-4 pb-0 mb-0"
                    >
                      <v-subheader class="text-h5 black--text">
                        Confirmar datos
                      </v-subheader>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" md="3" sm="6" xs="12">
                      <v-card flat outlined>
                        <v-card-title
                          class="text-h6"
                          :color="informacionGeneralRegistrada ? `#26c6da` : `#FFAB91`"
                        >
                          Información General
                        </v-card-title>

                        <!--  <v-card-subtitle
                          >Listen to your favorite artists and albums whenever and wherever,
                          online and offline.</v-card-subtitle
                        > -->

                        <v-card-text>
                          <v-row>
                            <v-col cols="12" md="12" class="text-center">
                              <v-icon
                                x-large
                                :color="
                                  informacionGeneralRegistrada ? `#26c6da` : `#FFAB91`
                                "
                                >{{
                                  informacionGeneralRegistrada
                                    ? "mdi-check-all"
                                    : "mdi-alert-circle-outline"
                                }}
                              </v-icon>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12" md="3" sm="6" xs="12">
                      <v-card flat outlined>
                        <v-card-title
                          class="text-h6"
                          :color="ejecucionFisicaRegistrada ? `#26c6da` : `#FFAB91`"
                        >
                          Ejecución Física
                        </v-card-title>

                        <!--  <v-card-subtitle
                          >Listen to your favorite artists and albums whenever and wherever,
                          online and offline.</v-card-subtitle
                        > -->

                        <v-card-text>
                          <v-row>
                            <v-col cols="12" md="12" class="text-center">
                              <v-icon
                                x-large
                                :color="ejecucionFisicaRegistrada ? `#26c6da` : `#FFAB91`"
                                >{{
                                  ejecucionFisicaRegistrada
                                    ? "mdi-check-all"
                                    : "mdi-alert-circle-outline"
                                }}
                              </v-icon>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12" md="3" sm="6" xs="12">
                      <v-card flat outlined>
                        <v-card-title
                          class="text-h6"
                          :color="ejecucionFinancieraRegistrada ? `#26c6da` : `#FFAB91`"
                        >
                          Ejecución financiera
                        </v-card-title>

                        <!--  <v-card-subtitle
                          >Listen to your favorite artists and albums whenever and wherever,
                          online and offline.</v-card-subtitle
                        > -->

                        <v-card-text>
                          <v-row>
                            <v-col cols="12" md="12" class="text-center">
                              <v-icon
                                x-large
                                :color="ejecucionFinancieraRegistrada ? `#26c6da` : `#FFAB91`"
                                >{{
                                  ejecucionFinancieraRegistrada
                                    ? "mdi-check-all"
                                    : "mdi-alert-circle-outline"
                                }}
                              </v-icon>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="12" md="3" sm="6" xs="12">
                      <v-card flat outlined>
                        <v-card-title
                          class="text-h6"
                          :color="documentosRegistrados ? `#26c6da` : `#FFAB91`"
                        >
                          Documentos
                        </v-card-title>

                        <!--  <v-card-subtitle
                          >Listen to your favorite artists and albums whenever and wherever,
                          online and offline.</v-card-subtitle
                        > -->

                        <v-card-text>
                          <v-row>
                            <v-col cols="12" md="12" class="text-center">
                              <v-icon
                                x-large
                                :color="documentosRegistrados ? `#26c6da` : `#FFAB91`"
                                >{{
                                  documentosRegistrados
                                    ? "mdi-check-all"
                                    : "mdi-alert-circle-outline"
                                }}
                              </v-icon>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                      sm="12"
                      class="pt-0 mt-4 pb-0 mb-0"
                    >
                      <v-subheader class="text-h5 black--text">
                        Datos del seguimiento
                      </v-subheader>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Fecha de inicio del seguimiento</v-list-item-title>
                          <v-list-item-subtitle>{{datosSeguimientoConfirmacion.fechaInicioSeguimiento | formatDate}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Fecha de finalización del seguimiento</v-list-item-title>
                          <v-list-item-subtitle>{{datosSeguimientoConfirmacion.fechaFinalizacionSeguimiento | formatDate}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>

                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Porcentaje ejecución física</v-list-item-title>
                          <v-list-item-subtitle>{{datosSeguimientoConfirmacion.porcentajeEjecucionFisica}} %</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Información de la ejecución física</v-list-item-title>
                          <v-list-item-subtitle>{{datosSeguimientoConfirmacion.informacionAdicionalFisica}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>

                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Porcentaje ejecución financiera</v-list-item-title>
                          <v-list-item-subtitle>{{datosSeguimientoConfirmacion.porcentajeEjecucionFinanciera}} %</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Información de la ejecución financiera</v-list-item-title>
                          <v-list-item-subtitle>{{datosSeguimientoConfirmacion.informacionAdicionalFinanciera}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>

                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Fecha de tipo de cambio</v-list-item-title>
                          <v-list-item-subtitle>{{datosSeguimientoConfirmacion.fechaTipoCambio | formatDate}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Costo ejecutado en dólares</v-list-item-title>
                          <v-list-item-subtitle>{{
                            parseFloat(datosSeguimientoConfirmacion.costoEjecutadoDolares).toLocaleString(
                              "es-GT",
                              {
                                style: "currency",
                                currency: "USD",
                                maximumFractionDigits: 2
                              }
                            )

                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>

                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Tipo de cambio</v-list-item-title>
                          <v-list-item-subtitle>{{
                            parseFloat(datosSeguimientoConfirmacion.tipoCambio).toFixed(2)

                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                    <v-col cols="12" md="6" sm="6" xs="12" class="pt-0 pb-0">
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>Costo total en quetzales</v-list-item-title>
                          <v-list-item-subtitle>{{
                            parseFloat(datosSeguimientoConfirmacion.costoTotalQuetzales).toLocaleString("es-GT", {
                              style: "currency",
                              currency: "GTQ",
                              maximumFractionDigits: 2
                            })

                          }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                      sm="12"
                      class="pt-0 mt-4 pb-0 mb-0"
                    >
                      <v-subheader class="text-h6 black--text">
                        Documentos del seguimiento
                      </v-subheader>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" sm="12" xs="12" class="pt-0 pb-0">
                      <template>
                        <v-simple-table>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left text-subtitle-1 font-weight-bold black--text">
                                  Nombre del documento
                                </th>
                                <th class="text-left text-subtitle-1  font-weight-bold black--text">
                                  Sección
                                </th>
                                <th class="text-left text-subtitle-1  font-weight-bold black--text">
                                  Descargar
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="doc in datosSeguimientoConfirmacion.documentos"
                                :key="doc.id"
                              >
                                <td>{{ doc.nombreDocumento }}</td>
                                <td>{{ doc.seccionDocumento == 1 ? `Ejecución física` : `Documento adicional` }}</td>
                                <td>
                                  <v-btn
                                    v-if="doc.estadosId == 1"
                                    class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                                    small
                                    :disabled="btnEliminarLoading"
                                    depressed
                                    color="blue-grey lighten-5"
                                    @click="descargarArchivo(doc.pathDocumento)"
                                  >
                                    <v-icon left>mdi-file-download-outline</v-icon>
                                    Visualizar
                                  </v-btn>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </template>
                      <v-divider></v-divider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      md="12"
                      sm="12"
                      class="pt-0 text-center"
                      v-if="datosSeguimientoConfirmacion.estadosId === 1"
                    >
                      <v-btn
                        color="light-blue-502"
                        class="white--text mb-2"
                        type="submit"
                        :elevation="0"
                        @click="
                          mostrarDialogEstado(
                            datosSeguimientoConfirmacion.id,
                            4
                          )
                        "
                        :disabled="
                          datosSeguimientoConfirmacion.estadosId == 4 ||
                            datosSeguimientoConfirmacion.estadosId == 2 ||
                            (!informacionGeneralRegistrada ||
                              !ejecucionFisicaRegistrada ||
                              !ejecucionFinancieraRegistrada ||
                              !documentosRegistrados)
                        "
                        :loading="btnEstadoLoading"
                      >
                        Confirmar seguimiento
                      </v-btn>
                    </v-col>

                    <v-col
                      cols="12"
                      class="text-center"
                      v-if="datosSeguimientoConfirmacion.estadosId === 4"
                    >
                      <v-icon large color="#26c6da">
                        mdi-check-all
                      </v-icon>

                      <p class="text-h6">
                        Seguimiento Confirmado
                      </p>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--fin:: dialog registro -->

    <!--inicio:: dialog cambio estado-->
    <v-dialog
      v-model="dialogEstado"
      persistent
      max-width="800px"
      transition="scroll-y-transition"
      scrollable
    >
      <v-card tile>
        <v-card-title>
          {{ modalEstadoTitle }}
          <v-spacer></v-spacer>
          <v-btn
            icon
            :disabled="btnEstadoLoading"
            @click="dialogEstado = false"
            class="float-right"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p class="text-h6">
            Está seguro de
            {{ nuevoEstadoId === 1 ? `habilitar nuevamente` : nuevoEstadoId === 2 ? `inhabilitar` : nuevoEstadoId === 4 ? `confirmar` : `` }}
            el seguimiento seleccionado?
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey lighten-5"
            elevation="0"
            class="mb-2 float-right grey lighten-5"
            @click="dialogEstado = false"
            :disabled="btnEstadoLoading"
          >
            Cancelar
          </v-btn>
          <!--:disabled="!validDocForm" type="submit"-->
          <v-btn
            color="light-blue-502"
            class="white--text mb-2 float-right"
            type="submit"
            :elevation="0"
            :disabled="false"
            :loading="btnEstadoLoading"
            @click="actualizarEstadoItem"
          >
            {{ btnEstadoText }}
          </v-btn>
        </v-card-actions>

        <div style="flex: 1 1 auto"></div>
      </v-card>
    </v-dialog>
    <!--fin:: dialog cambio estado-->
    <!--Inicio:: Snack alert-->
    <SnackAlert ref="snackalert"></SnackAlert>
    <!-- Fin:: Snack alert-->

    <!--Dialog loader -->
    <DialogLoader
      :dialogVisible="dialogLoaderVisible"
      :text="dialogLoaderText"
      transition="scroll-y-transition"
    ></DialogLoader>
    <!---->
  </div>
</template>
<script>
import Vue from "vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from "moment";
import SnackAlert from "@/view/content/alerts/SnackAlert.vue";
import DialogLoader from "@/view/content/DialogLoader";
import DivisorColor from "@/view/content/biblioteca-componentes/DivisorColor.vue";
import validations from "@/core/untils/validations.js";
import { CARGAR_ARCHIVO } from "@/core/services/store/cargaarchivo.module";
import DownloadFile from "@/core/untils/downloadfile.js";
import {
  OBTENER_PROYECTO_CDES,
  REGISTRAR_SEGUIMIENTO_PROYECTO_CDES,
  OBTENER_SEGUIMIENTOS_PROYECTOS_CDES,
  ACTUALIZAR_ESTADO_SEGUIMIENTO_PROYECTO_CDES,
  REGISTRAR_EJECUCION_FISICA_SEGUIMIENTO_PROYECTO_CDES,
  REGISTRAR_EJECUCION_FINANCIERA_SEGUIMIENTO_PROYECTO_CDES,
  REGISTRAR_DOCUMENTO_SEGUIMIENTO_PROYECTO_CDES,
  OBTENER_DOCUMENTOS_SEGUIMIENTO_PROYECTOS_CDES
  
} from "@/core/services/store/proyectoscooperaciondescentralizada/proyectocooperaciondescentralizada.module";
import { OBTENER_ITEMS_CATALOGO } from "@/core/services/store/catalogos/catalogointerno.module";
moment.locale("es");
Vue.filter("formatDate", function(value) {
  if (value) {
    return moment(String(value)).format("DD/MM/YYYY");
  }
});

export default {
  name: "AdminSeguimientosCooperacionDescentralizada",
  components: {
    SnackAlert,
    DialogLoader,
    DivisorColor
  },
  data() {
    return {
      skeletonLoading: false,
      tab: null,
      validForm: false,
      dialogLoaderVisible: false,
      dialogLoaderText: "",
      datosProyecto: {},
      menuFechaInicioSeguimiento: false,
      menuFechaFinalizacionSeguimiento: false,
      dateFechaInicioSeguimiento: null,
      dateFechaFinalizacionSeguimiento: null,
      proyectoId: 0,
      codigoProyecto: "",
      dateFechaTipoCambio: null,
      menuFechaTipoCambio: false,
      modalTitle: "Registrar Seguimiento",
      btnRegistroLoading: false,
      seguimientoRegistrado: false,
      datosRegistro: {
        id: 0,
        proyectosCooperacionDescId: this.proyectoId,
        porcentajeEjecucionFisica: 0,
        informacionAdicionalFisica: "",
        porcentajeEjecucionFinanciera: 0,
        informacionAdicionalFinanciera: "",
        costoEjecutadoDolares: 0,
        tipoCambio: 0,
        costoTotalQuetzales: 0,
        estadosId: 1,
        usuarioCreacion: "sco"
      },
      datosEjecucionFisica: [],
      datosEjecucionFinanciera: [],
      porcentajeEjecucionFisica: "",
      porcentajeEjecucionFinanciera: "",
      costoEjecutadoDolares: "",
      tipoCambio: "",
      costoTotalQuetzales: "",
      dialogRegistro: false,
      seguimientos: [],
      tableLoading: false,
      filtro: "",
      dialogEstado: false,
      modalEstadoTitle: "",
      btnEstadoText: "",
      nuevoEstadoId: 0,
      idSeleccionado: 0,
      seguimientoId: 0,
      accion: 1,
      validFormEjecucionFisica: false,
      validFormEjecucionFinanciera: false,
      btnEjecucionFisicaLoading: false,
      btnEjecucionFinancieraLoading: false,
      archivo: [],
      btnRegistroArchivoLoading: false,
      documentosCargados:[],
      tableDocumentosLoading: false,
      datosDocumento: {
        seguimientoCooperacionDescId: this.seguimientoId,
        pathArchivo: "",
        nombreDocumento: "",
        seccionDocumento: ""
      },
      datosSeguimientoConfirmacion:{},
      validFormArchivo: false,
      informacionGeneralRegistrada: false,
      ejecucionFisicaRegistrada: false,
      ejecucionFinancieraRegistrada: false,
      documentosRegistrados: false,
      tiposDocumentos: [
        { id: 1, tipo: "Documento de ejecución física" },
        { id: 2, tipo: "Documento adicional" }
      ],
      archivoCargado: false,
      ...validations
    };
  },
  methods: {
    resetForm() {
      this.accion = 1;
      this.dateFechaFinalizacionSeguimiento = null;
      this.dateFechaInicioSeguimiento = null;
      this.datosRegistro = {
        id: 0,
        proyectosCooperacionDescId: this.proyectoId,
        porcentajeEjecucionFisica: 0,
        fechaInicioSeguimiento: "",
        fechaFinalizacionSeguimiento: "",
        informacionAdicionalFisica: "",
        porcentajeEjecucionFinanciera: 0,
        informacionAdicionalFinanciera: "",
        costoEjecutadoDolares: 0,
        tipoCambio: 0,
        costoTotalQuetzales: 0,
        estadosId: 1,
        usuarioCreacion: "sco"
      };
      this.porcentajeEjecucionFisica = "";
      this.porcentajeEjecucionFinanciera = "";
      this.costoEjecutadoDolares = "";
      this.tipoCambio = "";
      this.costoTotalQuetzales = "";
    },
    modalNuevo() {
      this.tab = "tab-1";
      this.seguimientoRegistrado = false;
      this.resetForm();
      this.dialogRegistro = true;
    },
    mostrarDialogEstado(id, tipo) {
      this.btnEstadoText = "Confirmar";
      this.modalEstadoTitle = "Inhabilitar seguimiento";
      this.nuevoEstadoId = 2;
      this.idSeleccionado = id;
      if (tipo == 1) {
        this.modalEstadoTitle = "Habilitar seguimiento";
        this.nuevoEstadoId = 1;
      } else if (tipo == 4) {
        this.modalEstadoTitle = "Confirmar seguimiento";
        this.nuevoEstadoId = 4;
      }
      this.dialogEstado = true;
    },

    onFileChange(e) {
      if (e) {
        //!?Validar que el tipo de archivo sea pdf
        if (
          e.type != "application/pdf" &&
          e.type != "image/jpeg" &&
          e.type != "image/png"
        ) {
          // Api call
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El archivo que desea ingresar no es válido.`
          );
          //console.log("no es un pdf..")
          //this.$refs.file.reset();
          document.querySelector("#docFile").value = "";
          this.$refs.docFile.reset();
          return false;
        }
        //?Validar que el tamaó del archivo no exceda los 10Mb
        if (e.size / 1024 / 1024 > 20) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `El tamaño del archivo excede el límite permitido (20Mb)`
          );
          // this.$refs.file.reset();
          document.querySelector("#docFile").value = "";
          this.$refs.docFile.reset();
          return false;
        }

        this.archivo = e;
      }
    },

    async obtenerDatosSeguimientoConfirmacion(seguimientoId) {
      this.datosSeguimientoConfirmacion = {};
      this.documentosRegistrados = false;
      this.informacionGeneralRegistrada = false;
      this.ejecucionFisicaRegistrada = false;
      this.ejecucionFinancieraRegistrada = false;
      this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `ProyectosCooperacionDescentralizada/Seguimiento/GetById/${seguimientoId}`})
        .then(res => {
          if (res.status === 200) {
            this.datosSeguimientoConfirmacion = res.data;
            this.idSeleccionado = this.datosSeguimientoConfirmacion.id;

            if (
              this.datosSeguimientoConfirmacion.fechaInicioSeguimiento != null
            )
              this.informacionGeneralRegistrada = true;

            if (
              this.datosSeguimientoConfirmacion.porcentajeEjecucionFisica !=
              null
            )
              this.ejecucionFisicaRegistrada = true;

            if (
              this.datosSeguimientoConfirmacion.porcentajeEjecucionFinanciera !=
              null
            )
              this.ejecucionFinancieraRegistrada = true;

            if (this.datosSeguimientoConfirmacion.documentos.length > 0)
              this.documentosRegistrados = true;
          }
        })
        .catch(() => {
          this.datosSeguimientoConfirmacion = [];
        });
    },

    formatDatePicker(date) {
      //console.log(date)
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    async obtenerDatosItem(idProyecto) {
      this.datosProyecto = {};
      this.skeletonLoading = true;

      await this.$store
        .dispatch(OBTENER_PROYECTO_CDES, { id: idProyecto })
        .then(res => {
          //console.logres
          if (res.status === 200) {
            this.datosProyecto = res.data;
          }
          //this.ddTiposInstrumentoLoading = false;
          this.skeletonLoading = false;
        })
        .catch(() => {
          this.skeletonLoading = false;
          this.datosProyecto = [];
          // this.ddTiposInstrumentoLoading = false;
        });
    },

    //Obtener los registros de seguimiento
    async obtenerItems() {
      this.seguimientos = [];
      this.tableLoading = true;
      //let estadoProyectoId = 2;

      this.$store
        .dispatch(OBTENER_SEGUIMIENTOS_PROYECTOS_CDES, {
          proyectoId: this.proyectoId,
          estadoId: 1
        })
        .then(res => {
          if (res.status === 200) {
            this.seguimientos = res.data;
          }
          //console.log(this.items)
          this.tableLoading = false;
        })
        .catch(() => {
          this.seguimientos = [];
          this.tableLoading = false;
        });
    },

    ///Registro de seguimiento
    async registrarItem() {
      this.btnRegistroLoading = true;
      this.datosRegistro.proyectosCooperacionDescId = this.proyectoId;
      this.datosRegistro.usuarioCreacion = "adm";

      if (this.accion === 1) this.datosRegistro.id = 0;

      //Validar que la fecha de finalización sea superior a la de inicio
      let dateInicio = new Date(
        moment(this.computedFechaInicio, "DD/MM/YYYY").format("YYYY-MM-DD")
      ).getTime();

      let dateFinalizacion = new Date(
        moment(this.computedFechaFinalizacion, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        )
      ).getTime();

      //console.log(dateInicio);
      //console.log(dateFinalizacion);

      if (dateFinalizacion < dateInicio) {
        this.$refs.snackalert.SnackbarShow(
          "warning",
          "Alerta",
          "La fecha de finalización debe ser superior a la fecha de inicio"
        );
        this.btnRegistroLoading = false;
        return;
      }

      this.datosRegistro.fechaInicioSeguimiento = moment(
        this.computedFechaInicio,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      this.datosRegistro.fechaFinalizacionSeguimiento = moment(
        this.computedFechaFinalizacion,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");
      this.datosRegistro.fechaTipoCambio = null;
      this.datosRegistro.porcentajeEjecucionFinanciera = null;
      this.datosRegistro.costoEjecutadoDolares = null;
      this.datosRegistro.tipoCambio = null;
      this.datosRegistro.costoTotalQuetzales = null;

      await this.$store
        .dispatch(REGISTRAR_SEGUIMIENTO_PROYECTO_CDES, {
          datos: this.datosRegistro
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerItems();
            this.datosRegistro.id = res.data;
            //this.dialogRegistro = false;
            //this.resetForm();
            this.seguimientoId = res.data;
            this.seguimientoRegistrado = true;
            this.accion = 2;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroLoading = false;
        })
        .catch(err => {
          console.log(err);
          this.btnRegistroLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    async registrarEjecucionFisica() {
      this.btnEjecucionFisicaLoading = true;
      this.datosRegistro.porcentajeEjecucionFisica = this.porcentajeEjecucionFisica;
      await this.$store
        .dispatch(REGISTRAR_EJECUCION_FISICA_SEGUIMIENTO_PROYECTO_CDES, {
          datos: this.datosRegistro
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEjecucionFisicaLoading = false;
        })
        .catch(err => {
          console.log(err);
          this.btnEjecucionFisicaLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    async registrarEjecucionFinanciera() {
      this.btnEjecucionFinancieraLoading = true;
      this.datosRegistro.porcentajeEjecucionFinanciera = this.porcentajeEjecucionFinanciera;
      if (this.computedFechaTipoCambio != null) {
        this.datosRegistro.fechaTipoCambio = moment(
          this.computedFechaTipoCambio,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");
        if (this.costoEjecutadoDolares == null) {
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            "Es necesario ingresar el costo ejecutado en dólares."
          );
        }

        this.datosRegistro.costoEjecutadoDolares = this.costoEjecutadoDolares;
        this.datosRegistro.tipoCambio = this.tipoCambio;
        this.datosRegistro.costoTotalQuetzales = this.costoTotalQuetzales;
      } else {
        this.datosRegistro.fechaTipoCambio = null;
        this.datosRegistro.costoEjecutadoDolares = null;
        this.datosRegistro.tipoCambio = null;
        this.datosRegistro.costoTotalQuetzales = null;
      }

      this.btnEjecucionFinancieraLoading = true;
      await this.$store
        .dispatch(REGISTRAR_EJECUCION_FINANCIERA_SEGUIMIENTO_PROYECTO_CDES, {
          datos: this.datosRegistro
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            this.obtenerItems();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEjecucionFinancieraLoading = false;
        })
        .catch(err => {
          console.log(err);
          this.btnEjecucionFinancieraLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    async obtenerDatosSeguimiento(item) {
      //console.log(item)
      this.tab = "tab-1";
      this.dialogLoaderText = "Obteniendo datos...";
      this.dialogLoaderVisible = true;
      this.seguimientoRegistrado = true;
      this.seguimientoId = item.id;
      this.datosRegistro.id = item.id;
      this.dateFechaInicioSeguimiento = moment(
        item.fechaInicioSeguimiento,
        "YYYY-MM-DD"
      ).format("YYYY-MM-DD");
      this.dateFechaFinalizacionSeguimiento = moment(
        item.fechaFinalizacionSeguimiento,
        "YYYY-MM-DD"
      ).format("YYYY-MM-DD");
      this.datosRegistro.estadosId = item.estadosId;

      if (item.porcentajeEjecucionFisica) {
        this.porcentajeEjecucionFisica = item.porcentajeEjecucionFisica.toString();
      } else {
        this.porcentajeEjecucionFisica = "";
      }

      if (item.porcentajeEjecucionFinanciera) {
        this.porcentajeEjecucionFinanciera = item.porcentajeEjecucionFinanciera.toString();
      } else {
        this.porcentajeEjecucionFinanciera = "";
      }

      if (item.costoEjecutadoDolares) {
        this.costoEjecutadoDolares = item.costoEjecutadoDolares.toString();
      } else {
        this.costoEjecutadoDolares = "";
      }

      if (item.tipoCambio) {
        this.tipoCambio = item.tipoCambio.toString();
      } else {
        this.tipoCambio = "";
      }

      if (item.costoTotalQuetzales) {
        this.costoTotalQuetzales = item.costoTotalQuetzales.toString();
      } else {
        this.costoTotalQuetzales = "";
      }

      if (item.informacionAdicionalFisica) {
        this.datosRegistro.informacionAdicionalFisica = item.informacionAdicionalFisica;
      } else {
        this.datosRegistro.informacionAdicionalFisica = "";
      }

      if (item.informacionAdicionalFinanciera) {
        this.datosRegistro.informacionAdicionalFinanciera = item.informacionAdicionalFinanciera;
      } else {
        this.datosRegistro.informacionAdicionalFinanciera = "";
      }

      if (item.fechaTipoCambio) {
        this.dateFechaTipoCambio = moment(
          item.fechaTipoCambio,
          "YYYY-MM-DD"
        ).format("YYYY-MM-DD");
      } else {
        this.dateFechaTipoCambio = null;
      }
      this.obtenerDocumentosCargados(this.seguimientoId);
      this.accion = 2;
      this.dialogLoaderVisible = false;
      this.dialogRegistro = true;
    },

    async actualizarEstadoItem() {
      this.btnEstadoLoading = true;
      await this.$store
        .dispatch(ACTUALIZAR_ESTADO_SEGUIMIENTO_PROYECTO_CDES, {
          id: this.idSeleccionado,
          estadoId: this.nuevoEstadoId
        })
        .then(res => {
          //this.dialogLoaderVisible = false;
          if (res.status === 200) {
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            if (this.nuevoEstadoId == 4) {
              this.datosSeguimientoConfirmacion.estadosId == 4;
            }
            this.obtenerItems();
            this.dialogEstado = false;
            this.dialogRegistro = false;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnEstadoLoading = false;
        })
        .catch(err => {
          console.log(err);
          this.btnEstadoLoading = false;
          this.$refs.snackalert.SnackbarShow(
            "warning",
            "Alerta",
            `Ha ocurrido un error durante el registro.`
          );
        });
    },

    ///Confirmar el seguimiento
    confirmarSeguimiento() {
      this.nuevoEstadoId = 4;
      this.actualizarEstadoItem();
    },

    async cargarArchivo() {
      const files = this.archivo;
      let path = "";
      this.btnRegistroArchivoLoading = true;
      // this.datosArchivo={};
      //Definir la ruta segun el tipo de archivo

      path = "\\cdes\\documentos\\";

      await this.$store
        .dispatch(CARGAR_ARCHIVO, { file: files, path: path })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            this.archivoCargado = true;

            //Agregar el path en función del tipo de archivo que se cargó

            //this.datosDocumento.pathDocumentoRespaldo = res.pathArchivo;
            this.datosDocumento.pathDocumento = res.pathArchivo;

            //this.$refs.snackalert.SnackbarShow('success', 'Mensaje', res.mensaje);
            this.registrarPath();
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.mensaje
            );
            this.archivoCargado = false;
            this.btnRegistroArchivoLoading = false;
          }
          //this.btnRegistroLoading=false;
        })
        .catch(error => {
          this.$refs.snackalert.SnackbarShow("warning", "Mensaje", error);
          this.btnRegistroArchivoLoading = false;
        });
    },

    async registrarPath() {
      this.datosDocumento.seguimientoCooperacionDescId = this.seguimientoId;
      this.datosDocumento.estadosId = 1;
      this.datosDocumento.usuarioCreacion = "sco";
      //this.datosDocumento.proyectosCNSId = this.id;

      await this.$store
        .dispatch(REGISTRAR_DOCUMENTO_SEGUIMIENTO_PROYECTO_CDES, {
          datos: this.datosDocumento
        })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            // this.obtnenerItems();
            // this.dialog=false;
            this.$refs.snackalert.SnackbarShow(
              "success",
              "Mensaje",
              res.message
            );
            //if(this.archivo && this.archivo .size){
            //console.log("aui")
            //document.querySelector('#respaldoFile').value = '';
            //this.$refs.respaldoFile.reset();
            //this.$refs.docFile.reset();
            this.archivo = [];
            document.querySelector("#docFile").value = "";
            this.$refs.docFile.reset();
            this.datosDocumento.nombreDocumento = "";
            this.obtenerDocumentosCargados(this.seguimientoId);
            //this.resetFormArchivo();
            //this.$refs['respaldoFile'][0].reset();
            //}

            this.btnRegistroArchivoLoading = false;
          } else {
            this.$refs.snackalert.SnackbarShow(
              "warning",
              "Alerta",
              res.message
            );
          }
          this.btnRegistroArchivoLoading = false;
        })
        .catch(error => {
          //console.log(error)
          this.$refs.snackalert.SnackbarShow("warning", "Alerta", error);
          this.btnRegistroArchivoLoading = false;
        });
    },

    async obtenerDocumentosCargados(seguimientoId) {
      this.documentosCargados = [];
      //tipo===1 ? this.skeletonLoading = true : this.tableLoadingDocumentos =true;
      this.tableDocumentosLoading = true;
      await this.$store
        .dispatch(OBTENER_DOCUMENTOS_SEGUIMIENTO_PROYECTOS_CDES, {
          estadoId: 1,
          seguimientoId: seguimientoId,
        })
        .then(res => {
          //console.log(res)
          if (res.status === 200) {
            this.documentosCargados = res.data;
          }
          this.tableDocumentosLoading = false;
        })
        .catch(() => {
          this.documentosCargados = [];
          this.tableDocumentosLoading = false;
          /* tipo===1 ? this.skeletonLoading = false : this.tableLoadingDocumentos =false; */
        });
    },
    //!Descargar el archivo de respaldo
    descargarArchivo(path) {
      /* let newPath = path.replace(/\\/g, '/');
            //console.log(newPath);
            window.open("http://localhost:5236"+ newPath, "_blank"); */
      DownloadFile.download(path);
    },

    dateFechaTipoCambioClear() {
      this.computedFechaTipoCambio = null;
      this.dateFechaTipoCambio = null;
    }
  },

  created() {
    this.parameters = this.$route.params;
    this.proyectoId = parseInt(
      this.$CryptoJS.AES.decrypt(
        this.parameters.proyectoId,
        "KEYADMINCNS2022"
      ).toString(this.CryptoJS.enc.Utf8)
    );
    this.codigoProyecto = this.parameters.codigoProyecto;
    this.obtenerDatosItem(this.proyectoId);
    this.obtenerItems();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "/dashboard-seguimiento" },
      { title: "Cooperación descentralizada" },
      { title: "Seguimiento" }
    ]);
  },
  computed: {
    headers() {
      return [
        {
          text: "Fecha de Registro",
          align: "start",
          sortable: false,
          value: "fechaCreacion"
        },
        {
          text: "Fecha de inicio",
          align: "start",
          sortable: false,
          value: "fechaInicioSeguimiento"
        },
        {
          text: "Fecha de finalización",
          align: "start",
          sortable: true,
          value: "fechaFinalizacionSeguimiento"
        },
        {
          text: "% Ejecución Física",
          align: "start",
          sortable: true,
          value: "porcentajeEjecucionFisica"
        },
        {
          text: "% Ejecución financiera",
          align: "start",
          sortable: true,
          value: "porcentajeEjecucionFisica"
        },
        {
          text: "Costo Ejecutado $",
          align: "start",
          sortable: true,
          value: "costoEjecutadoDolares"
        },
        {
          text: "Tipo de cambio",
          align: "start",
          sortable: true,
          value: "tipoCambio"
        },
        {
          text: "Costo Ejecutado $",
          align: "start",
          sortable: true,
          value: "costoTotalQuetzales"
        },
        {
          text: "Estado",
          align: "start",
          sortable: true,
          value: "Estado"
        },
        {
          text: "Acciones",
          align: "start",
          sortable: true,
          value: "acciones"
        }
      ];
    },
    headersDocumentos() {
      {
        return [
          {
            text: "Tipo de documento",
            align: "start",
            sortable: false,
            value: "tipoDocumento"
          },
          {
            text: "Nombre del documento",
            align: "start",
            sortable: false,
            value: "nombreDocumento"
          },
          {
            text: "Acciones",
            align: "start",
            sortable: true,
            value: "pathDocumento"
          }
        ];
      }
    },
    computedFechaFinalizacion: {
      get() {
        //console.log("asdf")
        return this.formatDatePicker(this.dateFechaFinalizacionSeguimiento);
      },
      set(newValue) {
        //console.log(newValue)
        return this.formatDatePicker(
          moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
      }
    },
    computedFechaInicio: {
      get() {
        //console.log("asdf")
        return this.formatDatePicker(this.dateFechaInicioSeguimiento);
      },
      set(newValue) {
        //console.log(newValue)
        return this.formatDatePicker(
          moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
      }
    },

    computedFechaTipoCambio: {
      get() {
        //console.log("asdf")
        return this.formatDatePicker(this.dateFechaTipoCambio);
      },
      set(newValue) {
        //console.log(newValue)
        return this.formatDatePicker(
          moment(newValue, "DD/MM/YYYY").format("YYYY-MM-DD")
        );
      }
    }
  },
  watch: {
    costoEjecutadoDolares: function() {
      this.costoTotalQuetzales = parseFloat(
        this.costoEjecutadoDolares * this.tipoCambio
      )
        .toFixed(2)
        .toString();
    },

    tipoCambio: function() {
      this.costoTotalQuetzales = parseFloat(
        this.costoEjecutadoDolares * this.tipoCambio
      )
        .toFixed(2)
        .toString();
    }
  }
};
</script>
<style lang=""></style>
